"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/*
 * Author: Andries van Weeren
 * E-mail: a.vanweeren@fcroc.nl
 * Project: fcsource4
 * Created: 25/11/2022 13:55
 * Filename: main.ts
 */
require("./css/classroom.scss");
const ClassroomInvitation_1 = require("./ts/ClassroomInvitation");
const app = new ClassroomInvitation_1.ClassroomInvitation();
app.init();
