"use strict";
/*
 * Author: Andries van Weeren
 * E-mail: a.vanweeren@fcroc.nl
 * Project: fcsource4
 * Created: 25/11/2022 13:55
 * Filename: ClassroomTopBar
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClassroomInvitation = void 0;
/**
 * @class ClassroomTopBar
 */
class ClassroomInvitation {
    init() {
        let s = this;
        s.events();
    }
    events() {
        let s = this;
        $('.btn-accept-class-invitation').on('click', (e) => {
            s.respondInvite(e.currentTarget, 1, (e) => {
                window.location.reload();
            });
        });
        $('.btn-reject-class-invitation').on('click', (e) => {
            s.respondInvite(e.currentTarget, 9, (e) => {
                window.location.reload();
            });
        });
    }
    /**
     * @param {HTMLElement} elmnt
     * @param {number} state
     * @protected
     */
    respondInvite(elmnt, state, addition = null) {
        let s = this;
        $.ajax({
            url: '/api/classroom/class/invitation/state',
            data: {
                recordId: $(elmnt).data('id'),
                state: state
            },
            method: 'POST'
        }).done((response) => {
            if (addition !== null) {
                addition();
            }
        }).fail((jqXHR, textStatus, errorThrown) => {
        });
    }
}
exports.ClassroomInvitation = ClassroomInvitation;
